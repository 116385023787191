<script setup lang="ts">
import type { GenericOffer, InternetOffer, TvOffer } from "~/types/Offers";
const props = defineProps<{
  tvOffer?: TvOffer;
  internetOffer?: InternetOffer;
  hideBtn?: boolean;
  showPrice?: boolean;
  hideContract?: boolean;
  highlight: boolean;
  animate?: boolean;
  delay?: number;
  to?: any;
}>();

const loader = ref<HTMLElement>();

onMounted(() => {
  if (props.internetOffer) {
    setLoaderProperty(props.internetOffer.speed.download);
  }
  if (props.tvOffer) {
    setLoaderProperty(props.tvOffer?.channels.hd, 145, 100);
  }
  watch(
    () => props?.internetOffer,
    (newOffer, prevOffer) => {
      setLoaderProperty(newOffer?.speed.download);
    }
  );
  watch(
    () => props?.tvOffer,
    (newOffer, prevOffer) => {
      setLoaderProperty(newOffer?.channels.hd, 145, 100);
    }
  );
});

function setLoaderProperty(
  value?: number,
  divisor: number = 600,
  max: number = 75
) {
  if (loader.value) {
    loader.value.style.setProperty(
      "--percentage",
      `${(Math.min(value ?? 0, divisor) / divisor) * max}%`
    );
  }
}
</script>

<template>
  <div
    class="py-8 px-2 shadow flex flex-col items-center bg-gray-100 rounded-2xl gap-6"
  >
    <div
      v-if="internetOffer"
      class="bg-transparent rounded-full w-36 h-36 relative"
    >
      <div
        class="loader"
        ref="loader"
        :style="{
          '--percentage': (internetOffer.speed.download * 75) / 600 + '%',
        }"
      ></div>

      <div class="loader loader-top" :class="{ hidden: !highlight }"></div>
      <div
        class="text-center absolute bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2 transform-gpu"
      >
        <p class="text-4xl leading-9 font-extrabold">
          <TAnimValue as="plain" v-model="internetOffer.speed.download" />
        </p>
        <p class="font-bold leading-3">Mb/s</p>
      </div>
    </div>

    <div v-if="tvOffer" class="bg-transparent rounded-full w-36 h-36 relative">
      <div ref="loader" class="tv-decor select-none"     :style="{ '--percentage': (tvOffer.channels.hd * 100) / 145 + '%' }"></div>


      <div
        class="text-center w-full absolute bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2 transform-gpu"
      >
        <p class="text-4xl leading-9 font-extrabold">
          <TAnimValue v-model="tvOffer.channels.all" />
        </p>
        <p class="font-bold leading-3">{{ $t("offer.tv.channels") }}</p>
        <p class="text-sm w-full text-on-surface-variant">
          {{ $t("offers.tv.including.1") }}
          <TAnimValue v-model="tvOffer.channels.hd" />
          {{ $t("offers.tv.including.2") }}
        </p>
      </div>
    </div>

    <ul v-if="internetOffer" class="perks">
      <li>
        {{ $t("offer.download") }}
        <TAnimValue
          v-model="internetOffer.speed.download"
          as="netSpeed"
          :animate="true"
        />
      </li>
      <li>
        {{ $t("offer.upload") }}
        <TAnimValue
          v-model="internetOffer.speed.upload"
          as="netSpeed"
          :animate="true"
        />
      </li>
      <li v-for="perk in internetOffer.perks">
        {{ perk }}
      </li>
      <li v-if="!hideContract">
        {{ $t("offer.contract", [getContractString(internetOffer.contract)]) }}
      </li>
    </ul>

    <ul v-if="tvOffer" class="perks">
      <li v-for="perk in tvOffer.perks">
        {{ perk }}
      </li>

      <li v-if="!hideContract">
        {{ $t("offer.contract", [getContractString(tvOffer.contract)]) }}
      </li>
    </ul>
    <div v-if="showPrice" class="text-center">
      <p class="text-xl font-bold">
        <span>
          <TAnimValue
            v-if="internetOffer"
            as="currency"
            v-model="internetOffer.price"
          />
          <TAnimValue v-if="tvOffer" as="currency" v-model="tvOffer.price" />
        </span>
        <span>{{ $t("generic.perMo") }}</span>
      </p>
      <p class="text-sm font-semibold text-gray-500">
        <span>{{ $t("offer.activation") }} </span>
        <span>
          <TAnimValue
            v-if="internetOffer"
            as="currency"
            v-model="internetOffer.activation" />
          <TAnimValue v-if="tvOffer" as="currency" v-model="tvOffer.activation"
        /></span>
        {{ $t("dot") }}
      </p>
    </div>
    <UButton v-if="!hideBtn" size="lg" :to external
      >{{ $t("cta.checkOffer") }}
    </UButton>
  </div>
</template>

<style lang="css">
@property --percentage {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 0%;
}

@property --angle {
  syntax: "<angle>";
  inherits: false;
  initial-value: 0deg;
}
</style>
