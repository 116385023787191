const contractMap = {
    0: "nieokreślony",
    12: "12 miesięcy",
    24: "24 miesiące",
};




export function getContractString(length?: keyof typeof contractMap) {
    return contractMap[length ?? 0]
}