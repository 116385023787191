export function toCurrency(number: number, currency: "PLN" | "USD" | "EUR" = "PLN") {
    return new Intl.NumberFormat('pl-PL', { style: 'currency', currency: currency }).format(
        number
    )
}

export function toPercent(number: number) {
    return new Intl.NumberFormat('pl-PL', { style: 'percent', roundingPriority: "morePrecision" }).format(
        number
    )
}

export function toNetSpeed(number: number,) {
    return new Intl.NumberFormat('pl-PL', { style: 'unit', unit: "megabit-per-second" }).format(
        number
    )
}