<script setup lang="ts">
import noscript from "~/utils/noscript";
const props = withDefaults(
  defineProps<{
    as?: "percent" | "netSpeed" | "currency" | "plain";
    integer?: boolean;
    animate?: boolean;
  }>(),
  {
    as: "plain",
    integer: true,
    animate: false,
  }
);

const formatMethod = computed(
  () =>
    ({
      currency: (x: number) => toCurrency(x, "PLN"),
      percent: (x: number) => toPercent(x),
      netSpeed: (x: number) => toNetSpeed(x),
      plain: (x: number) => x.toFixed(0),
    }[props.as])
);

const model = defineModel<number>();
const valueDisplay = ref<HTMLElement>();
function animateTextValue(
  startValue: number,
  endValue: number,
  cb: (x: number) => string = formatMethod.value,
  duration: number = 300,
  int: boolean = props.integer
) {
  let startTime: number;
  const step = (timestamp: number) => {
    if (!startTime) startTime = timestamp;
    const progress = Math.min((timestamp - startTime) / duration, 1);

    const easedProgress =
      progress < 0.5
        ? 2 * progress * progress
        : -1 + (4 - 2 * progress) * progress;
    const interpolatedValue =
      startValue + easedProgress * (endValue - startValue);
    if (valueDisplay.value) {
      valueDisplay.value.textContent = cb(
        int ? Math.floor(interpolatedValue) : interpolatedValue
      );
    }

    if (progress < 1) {
      requestAnimationFrame(step);
    }
  };
  requestAnimationFrame(step);
}
onMounted(() => {
  if (valueDisplay.value && !props.animate) {
    valueDisplay.value.textContent = formatMethod.value(model.value as number);
  }
  if (valueDisplay.value && props.animate) {
    animateTextValue(0, model.value as number);
  }
});
watch(
  () => model.value,
  (newVal, prevVal) => {
    if (newVal === prevVal) {
      if (valueDisplay.value) {
        valueDisplay.value.textContent = formatMethod.value(
          model.value as number
        );
      }
    } else {
      animateTextValue(prevVal as number, newVal as number);
    }
  }
);
</script>

<template>
  <span ref="valueDisplay">
    {{ formatMethod(model ?? 0) }}
  </span>
</template>

<style lang="scss"></style>
